<template>
  <div class="mt-3 mb-5">
    <h2>Прогноз прихода подписчиков за первые 10 минут после выхода рекламы в сторис</h2>
    <div>
      <div class="hovering__pad p-4 mt-4 half-block">
        <div style="max-width: 340px">
          <label for="time-inp"
            >Прошло времени после выхода рекламы
            <DefaultTooltipMark
              v-b-tooltip.hover="
                'Выберите интервал времени, который прошёл после того, как блогер выложил рекламу'
              "
              class="ml-1"
          /></label>
          <b-form-select
            v-model="model.time"
            :options="timeOptions"
            style="max-width: 140px"
            id="time-inp"
          ></b-form-select>
        </div>

        <div class="mt-4" style="max-width: 376px">
          <b-form class="w-100" inline>
            <b-form-group class="mr-auto" label="Текущий приход">
              <b-form-input
                type="number"
                v-model="model.arrival"
                style="max-width: 173px !important"
              ></b-form-input>
            </b-form-group>
            <b-form-group placeholder="Необязательно" label="Стоимость рекламы, ₽">
              <b-form-input
                type="number"
                v-model="model.price"
                style="max-width: 153px !important"
              ></b-form-input>
            </b-form-group>
            <b-button @click="getResult" class="w-100 mt-4" variant="yellow"
              >Рассчитать приход с рекламы</b-button
            >
          </b-form>
        </div>
        <div
          class="p-4 mt-4 colored-block-predict"
          style="background: #fffce3; border-radius: 10px"
        >
          <div
            v-if="result.arrival && model.arrival"
            class="d-flex justify-content-md-start align-items-center align-items-sm-end mb-2"
          >
            <p class="fs-16">Прогнозируемый приход подписчиков:</p>
            <span class="ml-2" style="font-weight: 500; white-space: nowrap">{{
              result.arrival
            }}</span>
          </div>
          <div
            v-if="result.ppo && model.price"
            class="d-flex justify-content-md-start align-items-center align-items-sm-end mb-3"
          >
            <p class="fs-16">Прогнозируемая стоимость подписчика, ₽:</p>
            <span class="ml-2" style="font-weight: 500; white-space: nowrap">{{ result.ppo }}</span>
          </div>
          <!--b-button class="mb-3" variant="outline-default"
            >Как снизить стоимость подписчика, зная приход за первые 10 минут</!--b-button
          -->
          <b-button @click="openImproveModal" class="mt-1" variant="outline-default"
            >Что влияет на итоговую цену за подписчика?</b-button
          >
        </div>
        <p class="gray-text fs-12 mt-4">
          Если блогер удалит рекламу раньше чем пройдет 24 часа, то и приход подписчиков прекратится
        </p>
      </div>
    </div>
    <improve-modal
      v-if="showImproveModal"
      :model="model"
      :result="result"
      @hide="showImproveModal = false"
      :show="showImproveModal"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import wrappers from '@main/func/wrappers';
import DefaultTooltipMark from '@main/components/reusable/tooltips/marks/DefaultTooltipMark.vue';
import ImproveModal from './modals/ImproveModal.vue';

export default {
  components: {
    DefaultTooltipMark,
    ImproveModal,
  },
  data: () => ({
    showImproveModal: false,
    timeOptions: [
      { text: '10 минут', value: 10 },
      { text: '1 час', value: 60 },
    ],
    model: {
      time: 10,
      arrival: null,
      price: null,
    },
    result: {
      ppo: null,
      arrival: null,
    },
  }),
  methods: {
    ...mapMutations(['toggleSignIn']),
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    getArrival(coeff) {
      return this.model.arrival * coeff ** (-Math.floor(this.model.time / 60) + 2);
    },
    getPpo(arrival) {
      return (this.model.price / arrival).toFixed(1);
    },
    ppo() {
      return `${this.getPpo(this.arrivalMin)} – ${this.getPpo(this.arrivalMax)}`;
    },
    arrival() {
      return `${this.arrivalMin} – ${this.arrivalMax}`;
    },
    getResult() {
      wrappers.requiresSub(() => {
        this.result = {
          ppo: this.ppo(),
          arrival: this.arrival(),
          armin: this.arrivalMin,
          armax: this.arrivalMax,
        };
      }, '/#/arrival_predict/');
    },
    openImproveModal() {
      wrappers.requiresSub(() => {
        this.showImproveModal = true;
      });
    },
  },
  computed: {
    ...mapState(['currentUser']),
    arrivalMin() {
      return this.getArrival(4);
    },
    arrivalMax() {
      return this.getArrival(5);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 999px) {
  .half-block {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .colored-block-predict {
    border-radius: 0px !important;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
}
::v-deep .tooltip-inner {
  position: absolute;
  min-width: 230px;
  font-size: 12.7px !important;
  background-color: white !important;
  text-align: left !important;
  color: black !important;
  opacity: 1 !important;
  border: 1px solid #3897f0;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 73, 129, 0.1);
  border-radius: 5px;
  right: -83px;
}
</style>
